export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100', '200'];
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_SMALL = 10;
export const PAGE_SIZE_UNLIMITED = 9999999;
export const DEFAULT_PHONE_COUNTRY_CODE = 'US';
export const MAX_AVATAR_SIZE = 512;

export enum SocialProvider {
  Facebook = 1,
  Google,
  Twitter,
}
