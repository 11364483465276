import { flow, Instance, types } from 'mobx-state-tree';

import { DataUnit } from '#constants/size.constant';
import {
  GetSignedPartsResponse,
  GetSignedUrlRequest,
  GetSignedUrlResponse,
  InitMultipartUploadResponse,
} from '#models/model-types/FileModel';
import services from '#services';
import { getFileDigest, getFileMetadata } from '#utils/file.util';

import { withEnvironment } from '../extensions/with-environment';

export const FileStoreModel = types
  .model('FileStore')
  .props({})
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    uploadFile: flow(function* (file: File, onProgress?: ({ percent: number }) => void) {
      const request: GetSignedUrlRequest = {
        name: file.name,
        size: file.size,
        mime: file.type,
        digest: yield getFileDigest(file),
        metadata: yield getFileMetadata(file),
      };

      if (file.size < 20 * DataUnit.Megabyte) {
        const { signedUrl, file: createdFile }: GetSignedUrlResponse = yield services.file.getSignedUrl(request);
        yield services.file.uploadToS3(signedUrl, file, onProgress);
        return createdFile;
      }

      const {
        file: createdFile,
        path,
        uploadId,
        totalParts,
        partSize,
      }: InitMultipartUploadResponse = yield services.file.initMultipartUpload(request);

      const { signedParts }: GetSignedPartsResponse = yield services.file.getSignedParts({
        path,
        uploadId,
        parts: Array.from({ length: totalParts })
          .map((_, index) => index + 1)
          .join(','),
      });

      const uploadedParts: Array<{ ETag?: string; PartNumber?: number }> = yield Promise.all(
        signedParts.map(async ({ partNumber, signedUrl }) => {
          const blobStart = (partNumber - 1) * partSize;
          let blobEnd = blobStart + partSize;
          if (blobEnd > file.size) blobEnd = file.size;
          const blob = file.slice(blobStart, blobEnd);
          const response = await services.file.uploadToS3(signedUrl, blob);
          return { ETag: response.headers.etag, PartNumber: partNumber };
        }),
      );

      yield services.file.completeMultipartUpload({ path, uploadId, parts: uploadedParts });

      return createdFile;
    }),
  }));

type FileStore = Instance<typeof FileStoreModel>;
