import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { AdminStoreModel } from '#models/stores/admin-store';
import { AppStoreModel } from '#models/stores/app-store';
import { AuthStoreModel } from '#models/stores/auth-store';
import { CreatorStoreModel } from '#models/stores/creator-store';
import { FileStoreModel } from '#models/stores/file-store';
import { StripeStoreModel } from '#models/stores/stripe-store';

export const RootStoreModel = types
  .model('RootStore')
  .props({
    appStore: types.optional(AppStoreModel, {}),
    authStore: types.optional(AuthStoreModel, {}),
    fileStore: types.optional(FileStoreModel, {}),
    creatorStore: types.optional(CreatorStoreModel, {}),
    stripeStore: types.optional(StripeStoreModel, {}),
    adminStore: types.optional(AdminStoreModel, {}),
  })
  .actions((self) => ({
    reset() {
      console.log('handle reset');
    },
  }));

export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshotOut = SnapshotOut<typeof RootStoreModel>;
export type RootStoreSnapshotIn = SnapshotIn<typeof RootStoreModel>;
