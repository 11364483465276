import '#styles/globals.scss';
import '#styles/tailwind.scss';

import { Controls, Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import notification from 'antd/lib/notification';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'mobx-react';
import App, { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { appWithTranslation } from 'next-i18next';
import React from 'react';

import HeadMeta from '#components/HeadMeta';
import AuthProvider from '#components/hocs/AuthProvider';
import { useStore } from '#models';

const TopProgressBar = dynamic(
  () => {
    return import('#components/TopProgressBar');
  },
  { ssr: false },
);

notification.config({
  placement: 'topRight',
  top: 50,
  duration: 5,
});

dayjs.extend(localizedFormat);
dayjs.extend(utc);

const NextApp = ({ Component, pageProps }: AppProps) => {
  const store = useStore(pageProps.initialSnapshot);
  const [key, setKey] = React.useState(0);
  const [playerReady, setPlayerReady] = React.useState(false);
  const handlePlayerEvent = React.useCallback((event: PlayerEvent) => {
    if (event === 'load') {
      setPlayerReady(true);
    }
  }, []);

  React.useEffect(() => {
    setKey(Math.random());
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const handleWindowResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <>
      <HeadMeta />

      <TopProgressBar />

      <div id="page-loading-logo">
        <div className={classNames('loading-wrapper', { 'bg-white': playerReady })}>
          <Player
            autoplay={!store.authStore.isUserLoaded}
            loop
            src="/images/loading.json"
            className="loading-content"
            onEvent={handlePlayerEvent}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>

        <img src="/images/logo-name.svg" alt="Greenring" width={208} height={50} className="text" />
      </div>

      <Provider store={store}>
        <AuthProvider>
          <Component key={key} {...pageProps} />
        </AuthProvider>
      </Provider>
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
NextApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default appWithTranslation(NextApp);
