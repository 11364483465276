import { CreatorApplication } from '#models/model-types/CreatorModel';
import { User } from '#models/model-types/UserModel';

import { GreenringApi } from './base/Greenring.api';

export default class CreatorService {
  constructor(private api: GreenringApi) {}

  getCreator = async (slug: string): Promise<User> => {
    return this.api.get<User>(`/v1/creators/${slug}`);
  };

  getCreatorSlugs = async (): Promise<string[]> => {
    const { slugs } = await this.api.get<{ slugs: string[] }>(`/v1/creators/slugs`);
    return slugs;
  };

  getCreators = async (search?: string): Promise<User[]> => {
    try {
      const { users } = await this.api.get<{ users: User[] }>(`/v1/creators`, { search });
      return users;
    } catch (err) {
      console.log('getCreators error', err.message);
      return [];
    }
  };

  getApplication = async (uuid: string): Promise<CreatorApplication> => {
    return this.api.get<CreatorApplication>(`/v1/creators/applications/${uuid}`);
  };

  requestApplication = async (email: string): Promise<string> => {
    const { link } = await this.api.post<{ link: string }>(`/v1/creators/applications/request`, { email });
    return link;
  };

  submitApplication = async (application: Partial<CreatorApplication>): Promise<void> => {
    return this.api.post<void>(`/v1/creators/applications`, application);
  };
}
