import { Instance, types } from 'mobx-state-tree';

export interface PaymentMethod {
  id: string;
  type: 'card';
  card: {
    brand: 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'or' | 'unknown';
    funding: 'credit' | 'debit' | 'prepaid' | 'unknown';
    exp_month: number;
    exp_year: number;
    last4: string;
    country: string;
  };
  billing_details: {
    name: string | null;
    email: string | null;
    phone: string | null;
  };
  metadata: Record<string, unknown>;
}

export enum SubscriptionType {
  CloseFriend = 'CLOSE_FRIEND',
}

export enum SubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
  Refunded = 'refunded',
}

export const SubscriptionStatusColor = new Map<string, string>([
  [SubscriptionStatus.Active, '#22CE8C'],
  [SubscriptionStatus.Trialing, '#FECB47'],
  [SubscriptionStatus.Incomplete, '#FECB47'],
  [SubscriptionStatus.PastDue, '#FECB47'],
  [SubscriptionStatus.Canceled, '#ED4F2B'],
  [SubscriptionStatus.IncompleteExpired, '#ED4F2B'],
  [SubscriptionStatus.Unpaid, '#ED4F2B'],
  [SubscriptionStatus.Refunded, '#731DA2'],
]);

export const UserModel = types.model('User', {
  uid: types.identifier,
  email: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  avatarUrl: types.maybeNull(types.string),
  creatorFullName: types.maybeNull(types.string),
  creatorUsername: types.maybeNull(types.string),
  creatorPhotoUrl: types.maybeNull(types.string),
  creatorVideoUrl: types.maybeNull(types.string),
  creatorVideoThumbnailUrl: types.maybeNull(types.string),
  creatorVideoMetadata: types.optional(types.frozen(), {}),
  createdAt: types.optional(types.string, () => new Date().toISOString()),
  updatedAt: types.optional(types.string, () => new Date().toISOString()),
});

export const SubscriptionModel = types.model('Subscription', {
  uuid: types.identifier,
  userUid: types.string,
  userUsername: types.maybeNull(types.string),
  creatorUid: types.string,
  creatorUsername: types.maybeNull(types.string),
  user: types.maybeNull(UserModel),
  creator: types.maybeNull(UserModel),
  type: types.optional(types.enumeration(Object.values(SubscriptionType)), SubscriptionType.CloseFriend),
  subscriptionId: types.string,
  status: types.optional(types.enumeration(Object.values(SubscriptionStatus)), SubscriptionStatus.Active),
  productId: types.string,
  priceId: types.string,
  price: types.number,
  customerId: types.string,
  paymentMethodId: types.maybeNull(types.string),
  paymentMethod: types.maybeNull(types.frozen()),
  latestInvoiceId: types.maybeNull(types.string),
  lastBillingTime: types.maybeNull(types.string),
  nextBillingTime: types.maybeNull(types.string),
  startAt: types.maybeNull(types.string),
  billingStartAt: types.maybeNull(types.string),
  trialStartAt: types.maybeNull(types.string),
  trialEndAt: types.maybeNull(types.string),
  canceled: types.boolean,
  canceledAt: types.maybeNull(types.string),
  cancelAt: types.maybeNull(types.string),
  endedAt: types.maybeNull(types.string),
  metadata: types.maybeNull(types.frozen()),
  extraData: types.maybeNull(types.frozen()),
  cfAdded: types.optional(types.boolean, false),
  cfLog: types.maybeNull(types.string),
  refundId: types.maybeNull(types.string),
  refundInvoiceId: types.maybeNull(types.string),
  refundAmount: types.maybeNull(types.number),
  refundReason: types.maybeNull(types.string),
  invoices: types.array(types.late(() => InvoiceModel)),
  createdAt: types.string,
  updatedAt: types.string,
});

export const isExpiredSubscription = (subscription?: Subscription, includeCanceled = true): boolean => {
  return (
    !subscription ||
    ![SubscriptionStatus.Active, SubscriptionStatus.Incomplete, SubscriptionStatus.PastDue].includes(
      subscription.status,
    ) ||
    (includeCanceled && !!subscription.canceled)
  );
};

export type Subscription = Instance<typeof SubscriptionModel>;

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
  Deleted = 'deleted',
}

export const InvoiceStatusColor = new Map<string, string>([
  [InvoiceStatus.Paid, '#22CE8C'],
  [InvoiceStatus.Draft, '#FECB47'],
  [InvoiceStatus.Open, '#FECB47'],
  [InvoiceStatus.Void, '#ED4F2B'],
  [InvoiceStatus.Uncollectible, '#ED4F2B'],
  [InvoiceStatus.Deleted, '#ED4F2B'],
]);

export const InvoiceModel = types.model('Invoice', {
  id: types.identifier,
  number: types.maybeNull(types.string),
  userUid: types.string,
  user: types.maybeNull(UserModel),
  subscriptionUuid: types.maybeNull(types.string),
  subscription: types.maybeNull(SubscriptionModel),
  paid: types.boolean,
  status: types.optional(types.enumeration(Object.values(InvoiceStatus)), InvoiceStatus.Open),
  customerId: types.string,
  subscriptionId: types.maybeNull(types.string),
  chargeId: types.maybeNull(types.string),
  paymentMethodId: types.maybeNull(types.string),
  paymentMethod: types.maybeNull(types.frozen()),
  currency: types.string,
  total: types.number,
  stripeFee: types.number,
  greenringFee: types.number,
  netAmount: types.number,
  periodStart: types.string,
  periodEnd: types.string,
  paidAt: types.maybeNull(types.string),
  nextPaymentAttempt: types.maybeNull(types.string),
  metadata: types.maybeNull(types.frozen()),
  refundId: types.maybeNull(types.string),
  refundAmount: types.maybeNull(types.number),
  refundReason: types.maybeNull(types.string),
  creatorFullName: types.maybeNull(types.string),
  refundMetaData: types.maybeNull(types.frozen()),
  createdAt: types.string,
  updatedAt: types.string,
});

export type Invoice = Instance<typeof InvoiceModel>;

export interface CreatePaymentIntentRequest {
  amount: number;
  metadata?: Record<string, string>;
}

export interface CreatePaymentIntentResponse {
  paymentIntentId: string;
  clientSecret: string;
}

export interface CreateSubscriptionRequest {
  creatorUid: string;
  paymentMethodId?: string;
  metadata?: Record<string, string>;
  waitlistEnabled?: boolean;
}

export interface UpdateSubscriptionRequest {
  userUsername?: string;
  paymentMethodId?: string;
}

export interface CreateSubscriptionResponse {
  subscriptionId: string;
  clientSecret?: string;
}

export interface GetPaymentMethodsResponse {
  paymentMethods: PaymentMethod[];
  defaultPaymentMethodId: string | null;
}

export interface CreatePaymentMethodRequest {
  paymentMethodId: string;
}

export interface GetSubscriptionsResponse {
  subscriptions: Subscription[];
  page: number;
  pageSize: number;
}

export interface GetInvoicesResponse {
  invoices: Invoice[];
  page: number;
  pageSize: number;
}

export interface GetInvoiceResponse {
  invoice: Invoice;
}

export interface GetEarningTotalResponse {
  thisMonth: number;
  allTime: number;
  allTimeGreenringFee: number;
}

export interface BulkRefundResponse {
  result: Array<{
    uuid: string;
    success: boolean;
    message?: string;
  }>;
}

export interface GetCreatorMonthlyEarningsResponse {
  earnings: Array<{
    timestamp: number;
    revenue: number;
    activeSubs: number;
    newSubs: number;
    greenringFee: number;
    stripeFee: number;
    netAmount: number;
  }>;
}
