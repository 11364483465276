import { flow, Instance, types } from 'mobx-state-tree';

import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
  CreatePaymentMethodRequest,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  GetEarningTotalResponse,
  GetPaymentMethodsResponse,
  Invoice,
  PaymentMethod,
  Subscription,
  UpdateSubscriptionRequest,
} from '#models/model-types/StripeModel';
import services from '#services';

import { withEnvironment } from '../extensions/with-environment';

export const StripeStoreModel = types
  .model('StripeStoreModel')
  .props({})
  .extend(withEnvironment)
  .views((self) => ({}))
  .volatile((self) => ({}))
  .actions((self) => ({
    createPaymentIntent: flow(function* (request: CreatePaymentIntentRequest) {
      const response: CreatePaymentIntentResponse = yield services.stripe.createPaymentIntent(request);
      return response;
    }),
  }))
  .actions((self) => ({
    createSubscription: flow(function* (request: CreateSubscriptionRequest) {
      const response: CreateSubscriptionResponse = yield services.stripe.createSubscription(request);
      return response;
    }),
    updateSubscription: flow(function* (uuid: string, request: UpdateSubscriptionRequest) {
      yield services.stripe.updateSubscription(uuid, request);
    }),
  }))
  .actions((self) => ({
    getPaymentMethods: flow(function* () {
      const response: GetPaymentMethodsResponse = yield services.stripe.getPaymentMethods();
      return response;
    }),
  }))
  .actions((self) => ({
    createPaymentMethod: flow(function* (request: CreatePaymentMethodRequest) {
      const paymentMethod: PaymentMethod = yield services.stripe.createPaymentMethod(request);
      return paymentMethod;
    }),
  }))
  .actions((self) => ({
    makePaymentMethodDefault: flow(function* (id: string) {
      yield services.stripe.makePaymentMethodDefault(id);
    }),
  }))
  .actions((self) => ({
    deletePaymentMethod: flow(function* (id: string) {
      yield services.stripe.deletePaymentMethod(id);
    }),
  }))
  .actions((self) => ({
    getMySubscriptions: flow(function* () {
      const subscriptions: Subscription[] = yield services.stripe.getMySubscriptions();
      return subscriptions;
    }),
    getSubscriberSubscriptions: flow(function* (page: number, pageSize: number, active: boolean) {
      const subscriptions: Subscription[] = yield services.stripe.getSubscriberSubscriptions(page, pageSize, active);
      return subscriptions;
    }),
    getSubscriberSubscriptionsTotal: flow(function* (active: boolean) {
      const total: number = yield services.stripe.getSubscriberSubscriptionsTotal(active);
      return total;
    }),
  }))
  .actions((self) => ({
    cancelSubscription: flow(function* (uuid: string) {
      yield services.stripe.cancelSubscription(uuid);
    }),
  }))
  .actions((self) => ({
    getSubscriptionLatestInvoice: flow(function* (uuid: string) {
      const invoice: Invoice = yield services.stripe.getSubscriptionLatestInvoice(uuid);
      return invoice;
    }),
  }))
  .actions((self) => ({
    getMyInvoices: flow(function* (page?: number, pageSize?: number) {
      const invoices: Invoice[] = yield services.stripe.getMyInvoices(page, pageSize);
      return invoices;
    }),
    getMyInvoicesTotal: flow(function* () {
      const total: number = yield services.stripe.getMyInvoicesTotal();
      return total;
    }),
  }))
  .actions((self) => ({
    getMyEarnings: flow(function* (page?: number, pageSize?: number) {
      const invoices: Invoice[] = yield services.stripe.getMyEarnings(page, pageSize);
      return invoices;
    }),
    getMyEarningsTotal: flow(function* () {
      const total: number = yield services.stripe.getMyEarningsTotal();
      return total;
    }),
  }))
  .actions((self) => ({
    getMyEarningStatistics: flow(function* () {
      const response: GetEarningTotalResponse = yield services.stripe.getMyEarningStatistics();
      return response;
    }),
  }));

type StripeStore = Instance<typeof StripeStoreModel>;
