import { cast, flow, Instance, types } from 'mobx-state-tree';

import { CreatorApplication } from '#models/model-types/CreatorModel';
import { User, UserModel } from '#models/model-types/UserModel';
import services from '#services';

import { withEnvironment } from '../extensions/with-environment';

export const CreatorStoreModel = types
  .model('CreatorStore')
  .props({
    creators: types.optional(types.array(UserModel), []),
    creator: types.maybe(UserModel),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .volatile((self) => ({
    searching: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    setCreator: function (creator?: User) {
      self.creator = creator && cast(creator);
    },
  }))
  .actions((self) => ({
    getCreators: flow(function* (search?: string) {
      self.searching = true;
      try {
        const creators: User[] = yield services.creator.getCreators(search);
        self.creators = cast(creators);
      } catch {
        self.creators = cast([]);
      } finally {
        self.searching = false;
      }
    }),
  }))
  .actions((self) => ({
    requestApplication: flow(function* (email: string) {
      const link: string = yield services.creator.requestApplication(email);
      return link;
    }),
    submitApplication: flow(function* (application: Partial<CreatorApplication>) {
      yield services.creator.submitApplication(application);
    }),
  }));

type CreatorStore = Instance<typeof CreatorStoreModel>;
