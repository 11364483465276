import { notification } from 'antd';
import { cast, flow, Instance, types } from 'mobx-state-tree';

import { PAGE_SIZE_DEFAULT } from '#constants/app.constant';
import services from '#services';
import { ListResponse, RequestParams } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const CloseFriendProcessedErrorModel = types.model({
  id: types.maybeNull(types.number),
  time: types.maybeNull(types.string),
  reason: types.string,
  subscriptionId: types.string,
  userUsername: types.string,
  metaData: types.optional(types.frozen(), {}),
  subscription: types.maybeNull(types.frozen()),
});

export const CloseFriendProcessedErrorListModel = types
  .model({
    data: types.optional(types.array(CloseFriendProcessedErrorModel), []),
    total: types.optional(types.number, 0),
    pageCount: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, PAGE_SIZE_DEFAULT),
  })
  .extend(withEnvironment)
  .volatile(() => ({
    isLoading: types.optional(types.boolean, false).create(),
    isUpdating: types.optional(types.boolean, false).create(),
    mailSending: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    getCloseFriendProcessedError: flow(function* (requestParams?: GetCloseFriendProcessedErrorRequest) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        const { data, total, pageCount }: ListResponse<CloseFriendProcessedErrorType> =
          yield services.admin.getCloseFriendProcessErrors({
            limit,
            page,
            ...(requestParams.sort?.length > 0 && { sort: requestParams.sort }),
            ...(requestParams.searchText && { searchText: requestParams.searchText }),
            ...(requestParams.searchText && { searchBy: requestParams.searchBy }),
            ...(typeof requestParams.subscriptionCfAdded !== 'undefined' && {
              subscriptionCfAdded: requestParams.subscriptionCfAdded,
            }),
          });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    getCloseFriendProcessedErrorToExport: flow(function* (requestParams?: GetCloseFriendProcessedErrorRequest) {
      return services.admin.getCloseFriendProcessErrors({
        ...(requestParams.sort?.length > 0 && { sort: requestParams.sort }),
        ...(requestParams.searchText && { searchText: requestParams.searchText }),
        ...(requestParams.searchText && { searchBy: requestParams.searchBy }),
        ...(typeof requestParams.subscriptionCfAdded !== 'undefined' && {
          subscriptionCfAdded: requestParams.subscriptionCfAdded,
        }),
      });
    }),
    sendCloseFriendErrorEmail: flow(function* (requestParams?: SendCloseFriendErrorEmailRequest) {
      self.mailSending = true;
      try {
        yield services.admin.sendCloseFriendErrorEmail(requestParams);
        notification.success({ message: 'Mail sent succesfully' });
      } catch (error: any) {
        notification.error({ message: error.message });
      } finally {
        self.mailSending = false;
      }
    }),
  }));

export type CloseFriendProcessedErrorType = Instance<typeof CloseFriendProcessedErrorModel>;
export interface GetCloseFriendProcessedErrorRequest extends RequestParams {
  subscriptionStatus?: string;
  subscriptionCfAdded?: boolean;
  customerEmail?: string;
}
export interface SendCloseFriendErrorEmailRequest {
  id: number;
}
