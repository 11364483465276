export const getFileBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const getFileDigest = async (file: File): Promise<string | null> => {
  return null;
};

export interface ImageMetadata {
  width: number;
  height: number;
}

export const getImageMetadata = async (file: File | Blob): Promise<ImageMetadata | null> => {
  const URL = window.URL || window.webkitURL; // work in client-side only
  return new Promise((resolve) => {
    const objectUrl = URL.createObjectURL(file);
    const img = new Image();
    img.onload = function (event) {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function (_event, _source, _lineno, _colno, error) {
      // console.error(error);
      resolve(null);
    };
    img.src = objectUrl;
  });
};

export interface VideoMetadata {
  width: number;
  height: number;
  duration: number;
}

export const getVideoMetadata = async (file: File | Blob): Promise<VideoMetadata | null> => {
  const URL = window.URL || window.webkitURL; // work in client-side only
  return new Promise((resolve) => {
    // load the file to a video player
    const video = document.createElement('video');
    video.setAttribute('src', URL.createObjectURL(file));
    video.load();
    video.addEventListener('error', ({ error }) => {
      console.log(error); // error when loading video file
      resolve(null);
    });
    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });

    // const reader = new FileReader();
    // reader.onload = function (event) {
    //   const blob = new Blob([event.target.result], { type: file.type }); // create a blob of buffer
    //   const url = URL.createObjectURL(blob); // create o-URL of blob
    //   const video = document.createElement('video'); // create video element
    //   video.preload = 'metadata'; // preload setting
    //
    //   video.addEventListener('loadedmetadata', function () {
    //     resolve({
    //       width: video.videoWidth,
    //       height: video.videoHeight,
    //       duration: video.duration,
    //     });
    //     URL.revokeObjectURL(url); // clean up
    //   });
    //
    //   video.addEventListener('error', function (error) {
    //     console.error(1, error);
    //     resolve(null);
    //   });
    //
    //   video.src = url; // start video
    // };
    // reader.onerror = function (event) {
    //   console.error(event.target.error);
    //   resolve(null);
    // };
    // reader.readAsArrayBuffer(file.slice(0, 500000 /* 5MB */));
  });
};

export const getVideoThumbnail = async (file: File | Blob, seekTo = 0.0): Promise<File | null> => {
  const URL = window.URL || window.webkitURL; // work in client-side only
  return new Promise((resolve) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', ({ error }) => {
      console.log(error); // error when loading video file
      resolve(null);
    });

    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        console.log('video is too short.');
        resolve(null);
        return;
      }

      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);

      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            if (!blob) {
              return resolve(null);
            }
            resolve(new File([blob], 'image.jpeg', { lastModified: Date.now(), type: blob?.type }));
          },
          'image/jpeg',
          0.75 /* quality */,
        );
      });
    });
  });
};

// https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
export const getImageThumbnail = async (file: File | Blob): Promise<File | null> => {
  const URL = window.URL || window.webkitURL; // work in client-side only
  return new Promise((resolve) => {
    const objectUrl = URL.createObjectURL(file);
    const img = new Image();
    const canvas = document.getElementById('img-resize-canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    img.onload = function (event) {
      // set size proportional to image
      canvas.height = canvas.width * (img.height / img.width);

      // step 1 - resize to 50%
      const oc = document.createElement('canvas');
      const octx = oc.getContext('2d');

      oc.width = img.width * 0.5;
      oc.height = img.height * 0.5;
      octx.drawImage(img, 0, 0, oc.width, oc.height);

      // step 2
      octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

      // step 3, resize to final size
      ctx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5, 0, 0, canvas.width, canvas.height);

      // save to file
      ctx.canvas.toBlob(
        (blob) => {
          resolve(new File([blob], 'image.jpeg', { lastModified: Date.now(), type: blob.type }));
        },
        'image/jpeg',
        0.75,
      );
    };
    img.onerror = function (_event, _source, _lineno, _colno, error) {
      console.error(error);
      resolve(null);
    };
    img.src = objectUrl;
  });
};

export const getUrlOfFile = (file: File | Blob): string => {
  return URL.createObjectURL(file);
};

export const getFileMetadata = async (file: File | Blob): Promise<ImageMetadata | VideoMetadata | null> => {
  if (file.type.startsWith('image')) {
    return getImageMetadata(file);
  }
  if (file.type.startsWith('video')) {
    return getVideoMetadata(file);
  }
  if (file.type.startsWith('audio')) {
    // TODO
  }
  return null;
};

export const isVideoPath = (path?: string): boolean => {
  if (!path) return false;
  const cleanPath = path.toLowerCase();
  return (
    cleanPath.endsWith('.mp4') ||
    cleanPath.endsWith('.mov') ||
    cleanPath.endsWith('.avi') ||
    cleanPath.endsWith('.mkv') ||
    cleanPath.endsWith('.wmv') ||
    cleanPath.endsWith('.flv') ||
    cleanPath.endsWith('.3gp')
  );
};
