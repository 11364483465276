import { cast, flow, types } from 'mobx-state-tree';

import { PAGE_SIZE_DEFAULT } from '#constants/app.constant';
import { GetCreatorMonthlyEarningsResponse, Invoice, InvoiceModel } from '#models/model-types/StripeModel';
import services from '#services';
import { ListResponse, RequestParams } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const InvoiceListModel = types
  .model({
    data: types.optional(types.array(InvoiceModel), []),
    total: types.optional(types.number, 0),
    pageCount: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, PAGE_SIZE_DEFAULT),
    sort: types.optional(types.array(types.string), []),
    filter: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .volatile(() => ({
    isLoading: types.optional(types.boolean, false).create(),
    isRefunding: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    getInvoices: flow(function* (requestParams?: RequestParams) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<Invoice> = yield services.admin.getInvoices({
          limit,
          page,
          sort,
          filter,
        });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    getCreatorEarnings: flow(function* (uid: string, requestParams?: RequestParams) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<Invoice> = yield services.admin.getCreatorEarnings(uid, {
          limit,
          page,
          sort,
          filter,
        });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    refundInvoice: flow(function* (invoiceId: string, reason?: string) {
      self.isRefunding = true;
      try {
        yield services.admin.refundInvoice(invoiceId, reason);
      } catch (error) {
        throw error;
      } finally {
        self.isRefunding = false;
      }
    }),
    getCreatorMonthlyEarnings: flow(function* (uid: string) {
      self.isLoading = true;
      try {
        const { earnings }: GetCreatorMonthlyEarningsResponse = yield services.admin.getCreatorMonthlyEarnings(uid);
        return earnings;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }));
