import {
  CloseFriendProcessedErrorType,
  GetCloseFriendProcessedErrorRequest,
  SendCloseFriendErrorEmailRequest,
} from '#models/model-types/CloseFriendProcessedErrorModel';
import { BestieResponse, CreatorApplication, PaymentHistoryResponse } from '#models/model-types/CreatorModel';
import {
  BulkRefundResponse,
  GetCreatorMonthlyEarningsResponse,
  GetEarningTotalResponse,
  Invoice,
  Subscription,
  UpdateSubscriptionRequest,
} from '#models/model-types/StripeModel';
import { User } from '#models/model-types/UserModel';
import { ListResponse, RequestParams } from '#types';

import { GreenringApi } from './base/Greenring.api';

export default class AdminService {
  constructor(private api: GreenringApi) {}

  getConsumers = (params?: RequestParams): Promise<ListResponse<User>> => {
    return this.api.get<ListResponse<User>>(`/v1/admin/consumers`, params);
  };

  getCreators = async (params?: RequestParams): Promise<ListResponse<User>> => {
    return this.api.get<ListResponse<User>>(`/v1/admin/creators`, params);
  };

  getSubscriptions = async (params?: RequestParams): Promise<ListResponse<Subscription>> => {
    return this.api.get<ListResponse<Subscription>>(`/v1/admin/subscriptions`, params);
  };

  cancelSubscription = async (uuid: string): Promise<void> => {
    await this.api.delete<void>(`/v1/admin/subscriptions/${uuid}`);
  };

  updateSubscription = async (uuid: string, request: UpdateSubscriptionRequest): Promise<void> => {
    await this.api.patch<void>(`/v1/admin/subscriptions/${uuid}`, request);
  };

  refundSubscriptions = async (uuids: string[]): Promise<BulkRefundResponse> => {
    return await this.api.post<BulkRefundResponse>(`/v1/admin/refund`, { uuids });
  };

  refundInvoice = async (invoiceId: string, reason?: string): Promise<void> => {
    return await this.api.patch<void>(`/v1/admin/invoices/${invoiceId}/refund`, { reason });
  };

  getInvoices = async (params?: RequestParams): Promise<ListResponse<Invoice>> => {
    return this.api.get<ListResponse<Invoice>>(`/v1/admin/invoices`, params);
  };

  getCreatorApplications = async (params?: RequestParams): Promise<ListResponse<CreatorApplication>> => {
    return this.api.get<ListResponse<CreatorApplication>>(`/v1/admin/creator-applications`, params);
  };

  getCreatorApplication = async (uuid: string): Promise<CreatorApplication> => {
    return this.api.get<CreatorApplication>(`/v1/admin/creator-applications/${uuid}`);
  };

  updateCreatorApplication = (uuid: string, body: Partial<CreatorApplication>): Promise<void> => {
    return this.api.patch<void>(`/v1/admin/creator-applications/${uuid}`, body);
  };

  resendCreatorApplicationStatusEmail = (uuid: string): Promise<void> => {
    return this.api.patch<void>(`/v1/admin/creator-applications/${uuid}/resend-email`);
  };

  getCreator = (uid: string): Promise<User> => {
    return this.api.get<User>(`/v1/admin/creators/${uid}`);
  };

  getCreatorSubscribers = (uid: string, params?: RequestParams): Promise<ListResponse<Subscription>> => {
    return this.api.get<ListResponse<Subscription>>(`/v1/admin/creators/${uid}/subscribers`, params);
  };

  getCreatorEarnings = (uid: string, params?: RequestParams): Promise<ListResponse<Invoice>> => {
    return this.api.get<ListResponse<Invoice>>(`/v1/admin/creators/${uid}/earnings`, params);
  };

  getCreatorMonthlyEarnings = (uid: string): Promise<GetCreatorMonthlyEarningsResponse> => {
    return this.api.get<GetCreatorMonthlyEarningsResponse>(`/v1/admin/creators/${uid}/monthly-earnings`);
  };

  getCreatorEarningStatistics = (uid: string): Promise<GetEarningTotalResponse> => {
    return this.api.get<GetEarningTotalResponse>(`/v1/admin/creators/${uid}/earnings/statistics`);
  };

  updateCreator = (uid: string, body: Partial<User>): Promise<void> => {
    return this.api.patch<void>(`/v1/admin/creators/${uid}`, body);
  };

  updateCreatorProfile = async (uid: string, body: Partial<CreatorApplication>): Promise<void> => {
    await this.api.patch<void>(`/v1/admin/creators/${uid}/profile`, body);
  };
  getCreatorBesties = async (
    creatorUsername: string,
    getNewest = false,
    config: { timeout?: number },
  ): Promise<BestieResponse[]> => {
    return this.api.get<BestieResponse[]>(
      `/v1/instagram/friend-list?instagramUsername=${creatorUsername}&getNewest=${getNewest}`,
      null,
      config,
    );
  };
  getPaymentHistories = async (userUid: string): Promise<PaymentHistoryResponse> => {
    return this.api.get<PaymentHistoryResponse>(`/v1/admin/users/${userUid}/payment-history`);
  };
  getCloseFriendProcessErrors = async (
    params?: GetCloseFriendProcessedErrorRequest,
  ): Promise<ListResponse<CloseFriendProcessedErrorType>> => {
    return this.api.get<ListResponse<CloseFriendProcessedErrorType>>(`/v1/admin/close-friend-processed-error`, params);
  };
  sendCloseFriendErrorEmail = async (params?: SendCloseFriendErrorEmailRequest): Promise<boolean | void> => {
    return this.api.post<boolean | void>(`/v1/admin/send-close-friend-processed-error-email`, params);
  };
}
