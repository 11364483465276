import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
  CreatePaymentMethodRequest,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  GetEarningTotalResponse,
  GetInvoiceResponse,
  GetInvoicesResponse,
  GetPaymentMethodsResponse,
  GetSubscriptionsResponse,
  Invoice,
  PaymentMethod,
  Subscription,
  UpdateSubscriptionRequest,
} from '#models/model-types/StripeModel';

import { GreenringApi } from './base/Greenring.api';

export default class StripeService {
  constructor(private api: GreenringApi) {}

  createPaymentIntent = async (request: CreatePaymentIntentRequest): Promise<CreatePaymentIntentResponse> => {
    return this.api.post<CreatePaymentIntentResponse>(`/v1/payment/payment-intents`, request);
  };

  createSubscription = async (request: CreateSubscriptionRequest): Promise<CreateSubscriptionResponse> => {
    return this.api.post<CreateSubscriptionResponse>(`/v1/payment/subscriptions`, request);
  };

  updateSubscription = async (uuid: string, request: UpdateSubscriptionRequest): Promise<void> => {
    return this.api.patch<void>(`/v1/payment/subscriptions/${uuid}`, request);
  };

  getPaymentMethods = async (): Promise<GetPaymentMethodsResponse> => {
    return this.api.get<GetPaymentMethodsResponse>(`/v1/payment/methods`);
  };

  createPaymentMethod = async (request: CreatePaymentMethodRequest): Promise<PaymentMethod> => {
    return this.api.post<PaymentMethod>(`/v1/payment/methods`, request);
  };

  makePaymentMethodDefault = async (id: string): Promise<void> => {
    return this.api.patch<void>(`/v1/payment/methods/${id}/default`);
  };

  deletePaymentMethod = async (id: string): Promise<void> => {
    return this.api.delete<void>(`/v1/payment/methods/${id}`);
  };

  getMySubscriptions = async (): Promise<Subscription[]> => {
    const { subscriptions } = await this.api.get<GetSubscriptionsResponse>(`/v1/payment/subscriptions`);
    return subscriptions;
  };

  getSubscriberSubscriptions = async (page?: number, pageSize?: number, active?: boolean): Promise<Subscription[]> => {
    const { subscriptions } = await this.api.get<GetSubscriptionsResponse>(`/v1/payment/subscriptions/subscribers`, {
      page,
      pageSize,
      active,
    });
    return subscriptions;
  };

  getSubscriberSubscriptionsTotal = async (active?: boolean): Promise<number> => {
    return await this.api.get<number>(`/v1/payment/subscriptions/subscribers/total`, { active });
  };

  cancelSubscription = async (uuid: string): Promise<void> => {
    await this.api.delete(`/v1/payment/subscriptions/${uuid}`);
  };

  getMyInvoices = async (page?: number, pageSize?: number): Promise<Invoice[]> => {
    const { invoices } = await this.api.get<GetInvoicesResponse>(`/v1/payment/invoices`, { page, pageSize });
    return invoices;
  };

  getMyInvoicesTotal = async (): Promise<number> => {
    return await this.api.get<number>(`/v1/payment/invoices/total`);
  };

  getSubscriptionLatestInvoice = async (uuid: string): Promise<Invoice> => {
    const { invoice } = await this.api.get<GetInvoiceResponse>(`/v1/payment/subscriptions/${uuid}/latest-invoice`);
    return invoice;
  };

  getMyEarnings = async (page?: number, pageSize?: number): Promise<Invoice[]> => {
    const { invoices } = await this.api.get<GetInvoicesResponse>(`/v1/payment/earnings`, { page, pageSize });
    return invoices;
  };

  getMyEarningsTotal = async (): Promise<number> => {
    return await this.api.get<number>(`/v1/payment/earnings/total`);
  };

  getMyEarningStatistics = async (): Promise<GetEarningTotalResponse> => {
    return this.api.get<GetEarningTotalResponse>(`/v1/payment/earnings/statistics`);
  };
}
