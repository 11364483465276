import { Instance, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

export const AppStoreModel = types
  .model('AppStore')
  .props({})
  .extend(withEnvironment)
  .views((self) => ({}))
  .volatile((self) => ({
    time: types.optional(types.Date, new Date()).create(),
  }))
  .actions((self) => ({
    setTime: function (time: Date) {
      self.time = time;
    },
  }));

type AppStore = Instance<typeof AppStoreModel>;
