import mergeWith from 'lodash/mergeWith';
import { applySnapshot, getSnapshot, onSnapshot } from 'mobx-state-tree';
import React from 'react';

import { Environment } from './environment';
import { RootStore, RootStoreModel, RootStoreSnapshotIn } from './stores/root-store';

/**
 * The key we'll be saving our state as within async storage.
 */
const STORE_SNAPSHOT_STORAGE_KEY = 'mobx-snapshot';

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
function createEnvironment() {
  return new Environment();
}

let store: RootStore | undefined;

/**
 * Setup the store.
 */
function initializeStore(initialSnapshot: Partial<RootStoreSnapshotIn> = null): RootStore {
  // prepare the environment that will be associated with the RootStore.
  const env = createEnvironment();
  const _store = store ?? RootStoreModel.create({}, env);

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (initialSnapshot) {
    applySnapshot(
      _store,
      mergeWith({}, getSnapshot(_store), initialSnapshot, (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) {
          return srcValue;
        }
      }),
    );
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }

  // Create the store once in the client
  if (!store) {
    store = _store;
    const clientSnapshot = window.localStorage.getItem(STORE_SNAPSHOT_STORAGE_KEY);
    if (clientSnapshot) {
      try {
        applySnapshot(
          store,
          mergeWith({}, JSON.parse(clientSnapshot), initialSnapshot, (objValue, srcValue) => {
            if (Array.isArray(objValue) && Array.isArray(srcValue)) {
              return srcValue;
            }
          }),
        );
      } catch (err) {
        console.error(err);
      }
    }
    store.reset();
  }

  // track changes & save to storage
  // onSnapshot(store, (snapshot) => window.localStorage.setItem(STORE_SNAPSHOT_STORAGE_KEY, JSON.stringify(snapshot)));

  return store;
}

export function useStore(initialSnapshot: Partial<RootStoreSnapshotIn> = null): RootStore {
  return React.useMemo(() => initializeStore(initialSnapshot), [initialSnapshot]);
}
