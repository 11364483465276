import Head from 'next/head';
import React from 'react';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const HeadMeta = ({
  title = 'Greenring',
  description = `Get added to your favorite creators Close Friend list on Instagram.`,
  image = '/images/logo.svg',
}: MetaProps): JSX.Element => {
  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="desc" name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=0"
      />

      {/* meta for facebook */}
      <meta key="ogtype" property="og:type" content="website" />
      <meta key="ogtitle" property="og:title" content={title} />
      <meta key="ogdesc" property="og:description" content={description} />
      <meta key="ogimage" property="og:image" content={image} />
      <meta key="ogsite" property="og:site_name" content="Greenring" />

      {/* meta for twitter */}
      <meta key="twcard" name="twitter:card" content="summary" />
      <meta key="twtitle" name="twitter:title" content={title} />
      <meta key="twdesc" name="twitter:description" content={description} />
      <meta key="twimage" name="twitter:image" content={image} />
    </Head>
  );
};

export default HeadMeta;
