import {
  InstagramLoginResponse,
  InstagramStatusResponse,
  InstagramVerifyOtpRequest,
} from '#models/model-types/CreatorModel';

import { GreenringApi } from './base/Greenring.api';

export default class InstagramService {
  constructor(private api: GreenringApi) {}

  login = async (username: string, password: string): Promise<InstagramLoginResponse> => {
    return this.api.post<InstagramLoginResponse>(
      `/v1/instagram/login`,
      {
        username,
        password,
      },
      { timeout: 120 * 1000 },
    );
  };

  verify = async (request: InstagramVerifyOtpRequest): Promise<void> => {
    return this.api.post<void>(`/v1/instagram/verify`, request, { timeout: 120 * 1000 });
  };

  status = async (username: string): Promise<InstagramStatusResponse> => {
    return this.api.get<InstagramStatusResponse>(`/v1/instagram/status/${username}`);
  };
}
