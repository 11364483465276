import AdminService from './Admin.service';
import AuthService, { COOKIE_ACCESS_TOKEN } from './Auth.service';
import { GeneralApi } from './base/GeneralApi';
import { GreenringApi } from './base/Greenring.api';
import CreatorService from './Creator.service';
import FileService from './File.service';
import InstagramService from './Instagram.service';
import IpService from './Ip.service';
import MiscService from './Misc.service';
import StripeService from './Stripe.service';

export { COOKIE_ACCESS_TOKEN };

interface Services {
  auth: AuthService;
  creator: CreatorService;
  file: FileService;
  stripe: StripeService;
  instagram: InstagramService;
  admin: AdminService;
  ip: IpService;
  misc: MiscService;
}

interface ServiceOptions {
  greenringApi?: GreenringApi;
  generalApi?: GeneralApi;
  isServer?: boolean;
  token?: string;
}

export const initServices = ({
  greenringApi = initGreenringApi(),
  generalApi = initGeneralApi(),
  isServer = false,
  token,
}: ServiceOptions): Services => {
  greenringApi.setAccessToken(token);
  const firebaseOptions = {
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID, // General Use
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID, // General Use
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY, // General Use
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN, // Auth with popup/redirect
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID, // Cloud Messaging
  };
  return {
    auth: new AuthService(greenringApi, firebaseOptions, isServer),
    creator: new CreatorService(greenringApi),
    file: new FileService(greenringApi, generalApi),
    stripe: new StripeService(greenringApi),
    instagram: new InstagramService(greenringApi),
    admin: new AdminService(greenringApi),
    ip: new IpService(generalApi),
    misc: new MiscService(greenringApi),
  };
};

const initGreenringApi = (): GreenringApi =>
  new GreenringApi(
    {
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
      timeout: parseInt(process.env.NEXT_PUBLIC_API_TIMEOUT_MS),
      responseType: 'json',
      // withCredentials: true,
      // headers: {
      //   crossDomain: true,
      // },
    },
    parseInt(process.env.NEXT_PUBLIC_API_RETRIES),
  );

const initGeneralApi = (): GeneralApi =>
  new GeneralApi({
    responseType: 'json',
    withCredentials: false,
    headers: {},
  });

export default initServices({});
