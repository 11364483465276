import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useStore } from '#models';
import { UserStatus } from '#models/model-types/UserModel';

const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const {
    authStore: {
      setUserLoaded,
      setAccessTokenClientSide,
      clearAccessTokenClientSide,
      getMyProfile,
      getMyMetadata,
      logout,
      createSession,
      updateSession,
      onIdTokenChanged,
    },
  } = useStore();

  useEffect(() => {
    const cancelAuthListener = onIdTokenChanged(async (firebaseUser) => {
      // always be trigged at page loads
      if (firebaseUser) {
        // User is signed in or token was refreshed.
        if (
          !!firebaseUser.providerData.find((provider) => provider?.providerId === 'password') &&
          !firebaseUser.emailVerified
        ) {
        }
        try {
          const { claims } = await firebaseUser.getIdTokenResult();
          const accessToken = await firebaseUser.getIdToken();
          setAccessTokenClientSide(accessToken);
          const user = await getMyProfile();
          console.log('profile', user);
          if (!user) {
            /*
             * getMyProfile return null in case of new account registration,
             * but still logged into firebase,
             * so DON'T log out of firebase here.
             */
          } else if (user.status !== UserStatus.Active) {
            throw new Error('User is not activated');
          } else {
            const metadata = await getMyMetadata();
            console.log('metadata', metadata);
            await createSession();
            await updateSession({ lastActive: new Date().toISOString() });
          }
        } catch (err) {
          console.error('onIdTokenChanged error', err);
          await logout();
        }
      } else {
        // User signed out/ not yet logged in
        clearAccessTokenClientSide();
      }

      setTimeout(() => {
        setUserLoaded(true);
        document.getElementById('page-loading-logo').classList.add('hidden');
        document.getElementsByTagName('body')?.[0]?.classList.remove('page-loading');
      }, 150);
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) =>
        console.log('event for the service worker', event),
      );

      // window.addEventListener('load', function () {
      //   navigator.serviceWorker
      //     .register('/service-worker.js')
      //     .then(function (reg) {
      //       reg.onupdatefound = function () {
      //         const installingWorker = reg.installing;
      //         installingWorker.onstatechange = function () {
      //           switch (installingWorker.state) {
      //             case 'installed':
      //               if (navigator.serviceWorker.controller) {
      //                 console.log('New or updated content is available.');
      //               } else {
      //                 console.log('Content is now available offline!');
      //               }
      //               break;
      //             case 'redundant':
      //               console.error('The installing service worker became redundant.');
      //               break;
      //           }
      //         };
      //       };
      //     })
      //     .catch(function (e) {
      //       console.error('Error during service worker registration:', e);
      //     });
      // });
    }

    return () => {
      cancelAuthListener();
    };
  }, []);

  return <>{children}</>;
});

export default AuthProvider;
