import { AxiosResponse } from 'axios';

export class GreenringApiError extends Error {
  constructor(public statusCode: number, code?: string, message?: string, public response?: AxiosResponse) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GreenringApiError);
    }
  }
}

export class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AssertionError';
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AssertionError);
    }
  }
}
