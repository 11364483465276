import { cast, flow, Instance, types } from 'mobx-state-tree';

import { CloseFriendProcessedErrorListModel } from '#models/model-types/CloseFriendProcessedErrorModel';
import { CreatorApplicationListModel } from '#models/model-types/CreatorApplicationListModel';
import { InvoiceListModel } from '#models/model-types/InvoiceListModel';
import { GetEarningTotalResponse, InvoiceModel, SubscriptionModel } from '#models/model-types/StripeModel';
import { SubscriptionListModel } from '#models/model-types/SubscriptionListModel';
import { UserListModel } from '#models/model-types/UserListModel';
import services from '#services';

import { withEnvironment } from '../extensions/with-environment';

export const AdminStoreModel = types
  .model('AdminStore')
  .props({
    consumerList: types.optional(UserListModel, {}),
    creatorList: types.optional(UserListModel, {}),
    subscriptionList: types.optional(SubscriptionListModel, {}),
    invoiceList: types.optional(InvoiceListModel, {}),
    creatorApplicationList: types.optional(CreatorApplicationListModel, {}),
    closeFriendProcessedErrorList: types.optional(CloseFriendProcessedErrorListModel, {}),
    creator: types.optional(
      types.model({
        earningTotal: types.optional(
          types
            .model({
              thisMonth: types.optional(types.number, 0),
              allTime: types.optional(types.number, 0),
              allTimeGreenringFee: types.optional(types.number, 0),
            })
            .actions((self) => ({
              getCreatorEarningStatistics: flow(function* (uid: string) {
                const { thisMonth, allTime, allTimeGreenringFee }: GetEarningTotalResponse =
                  yield services.admin.getCreatorEarningStatistics(uid);
                self.thisMonth = thisMonth;
                self.allTime = allTime;
                self.allTimeGreenringFee = allTimeGreenringFee;
              }),
            })),
          {},
        ),
        invoiceList: types.optional(InvoiceListModel, {}),
        subscriptionList: types.optional(SubscriptionListModel, {}),
      }),
      {},
    ),
    besties: types.optional(
      types.array(
        types.model({
          pk: types.number,
          username: types.string,
          fullName: types.string,
          isVerified: types.boolean,
          isBestie: types.boolean,
        }),
      ),
      [],
    ),
    paymentHistory: types.optional(
      types.model({
        subscriptions: types.optional(types.array(SubscriptionModel), []),
        invoices: types.optional(types.array(InvoiceModel), []),
        paymentMethods: types.frozen(),
        defaultPaymentMethodId: types.maybeNull(types.string),
      }),
      {},
    ),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .volatile((self) => ({
    isBestieLoading: types.optional(types.boolean, false).create(),
    bestiesGotErr: types.optional(types.string, '').create(),
    paymentHistoryLoading: types.optional(types.boolean, false).create(),
    paymentHistoryGotErr: types.optional(types.string, '').create(),
  }))
  .actions((self) => ({
    getBesties: flow(function* (
      creatorUsername: string,
      getNewest = false,
      config: { timeout: number } = { timeout: 120 * 1000 },
    ) {
      self.bestiesGotErr = '';
      self.isBestieLoading = true;

      try {
        self.besties = cast([]);
        const { closeFriendList } = yield services.admin.getCreatorBesties(creatorUsername, getNewest, config);
        self.besties = cast(closeFriendList);
      } catch (error) {
        self.bestiesGotErr = error.message;
        console.log(error);
      } finally {
        self.isBestieLoading = false;
      }
    }),
    getPaymentHistory: flow(function* (userUid) {
      self.paymentHistoryGotErr = '';
      self.paymentHistoryLoading = true;

      try {
        self.paymentHistory = cast({});
        const resData = yield services.admin.getPaymentHistories(userUid);
        self.paymentHistory = cast(resData);
      } catch (error) {
        self.paymentHistoryGotErr = error.message;
        console.log(error);
      } finally {
        self.paymentHistoryLoading = false;
      }
    }),
  }));

type AdminStore = Instance<typeof AdminStoreModel>;
