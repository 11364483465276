/* eslint-disable simple-import-sort/imports */
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';

import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import { IpLocation, RegisterRequest, User, UserMetadata, UserSession } from '#models/model-types/UserModel';
import { DeepPartial } from '#types';

import { GreenringApi } from './base/Greenring.api';
import { SocialProvider } from '#constants/app.constant';
import { CreatorApplication } from '#models/model-types/CreatorModel';

export const COOKIE_ACCESS_TOKEN = 'greenring-token';
export const SESSION_ID_KEY = 'greenring-sid';
export const DEVICE_ID_KEY = 'greenring-did';
export const SIGN_IN_EMAIL = 'greenring-sign-email';

export default class AuthService {
  private readonly firebaseAuth: firebase.auth.Auth;
  private readonly facebookProvider = new firebase.auth.FacebookAuthProvider();
  private readonly twitterProvider = new firebase.auth.TwitterAuthProvider();
  private readonly googleProvider = new firebase.auth.GoogleAuthProvider();
  private readonly phoneProvider: firebase.auth.PhoneAuthProvider;
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  private confirmationResult: firebase.auth.ConfirmationResult | undefined;

  constructor(private api: GreenringApi, private readonly firebaseOptions: Record<string, string>, isServer = false) {
    const firebaseApp = firebase.apps.length ? firebase.app() : firebase.initializeApp(firebaseOptions);
    this.firebaseAuth = firebaseApp.auth();
    this.phoneProvider = new firebase.auth.PhoneAuthProvider(); // firebase App instance must be initialized via firebase.initializeApp() before PhoneAuthProvider
    const tokenCookie = Cookies.get(COOKIE_ACCESS_TOKEN);
    if (tokenCookie) {
      api.setAccessToken(tokenCookie);
    }
    if (!isServer) {
      api.setNewAccessTokenFunc(this.newAccessTokenClientSide);
    }
  }

  newAccessTokenClientSide = async (forceRefresh = false): Promise<string> => {
    const firebaseUser = this.firebaseAuth.currentUser;
    if (!firebaseUser) {
      throw new Error('Null user');
    }
    const accessToken = await firebaseUser.getIdToken(forceRefresh);
    this.setAccessTokenClientSide(accessToken);
    return accessToken;
  };

  setAccessTokenClientSide = (accessToken: string): void => {
    this.api.setAccessToken(accessToken);
    Cookies.set(COOKIE_ACCESS_TOKEN, accessToken, { sameSite: 'lax' });
  };

  clearAccessTokenClientSide = (): void => {
    this.api.setAccessToken(undefined);
    Cookies.remove(COOKIE_ACCESS_TOKEN);
  };

  onIdTokenChanged = (callback: (a: firebase.User | null) => any): firebase.Unsubscribe => {
    return this.firebaseAuth.onIdTokenChanged(callback);
  };

  applyActionCode = async (code: string): Promise<void> => {
    await this.firebaseAuth.applyActionCode(code);
  };

  checkEmailExists = async (email: string): Promise<boolean> => {
    const { existing } = await this.api.get<{ existing: boolean }>(`/v1/auth/existence/email/${email}`);
    return existing;
  };

  validateUsername = async (username: string): Promise<boolean> => {
    const { valid } = await this.api.get<{ valid: boolean }>(`/v1/auth/validate/instagramUsername/${username}`);
    return valid;
  };

  checkPhoneExists = async (phone: string): Promise<boolean> => {
    const { existing } = await this.api.get<{ existing: boolean }>(`/v1/auth/existence/phone/${phone}`);
    return existing;
  };

  checkPasswordAuthExists = async (email: string): Promise<{ existing: boolean; passwordResetRequired: boolean }> => {
    return this.api.get<{ existing: boolean; passwordResetRequired: boolean }>(`/v1/auth/existence/password/${email}`);
  };

  signUpWithEmail = async ({ email, password, ...rest }: RegisterRequest): Promise<firebase.User> => {
    await this.firebaseAuth.createUserWithEmailAndPassword(email, password);
    const firebaseUser = this.firebaseAuth.currentUser;
    const accessToken = await firebaseUser.getIdToken();
    this.setAccessTokenClientSide(accessToken);
    try {
      const user = await this.api.post<User>('/v1/auth/register', { email, ...rest });
      firebaseUser.reload().then(() => {
        if (!firebaseUser.emailVerified) {
          firebaseUser.sendEmailVerification();
        }
      });
      return firebaseUser;
    } catch (err) {
      firebaseUser.delete();
      console.error(err);
      throw err;
    }
  };

  requestSignUpWithPhone = async (phone: string): Promise<void> => {
    try {
      this.recaptchaVerifier ??= new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
      this.confirmationResult = await this.firebaseAuth.signInWithPhoneNumber(phone, this.recaptchaVerifier);
      // SMS sent.
      // Prompt user to type the code from the message, then sign the user in with confirmationResult.confirm(code).
    } catch (err) {
      // Error. SMS not sent.
      console.error(err);
      throw err;
    }
  };

  confirmSignUpWithPhone = async (
    code: string,
    { email, password, ...rest }: RegisterRequest,
  ): Promise<firebase.User> => {
    if (!this.confirmationResult) {
      throw new Error('Please try to get a new OTP.');
    }

    try {
      const {
        user: firebaseUser,
        additionalUserInfo: { isNewUser },
      } = await this.confirmationResult.confirm(code);
      // User signed up successfully.
      try {
        if (!isNewUser) {
          const error = new Error('Phone number is already in use');
          error['code'] = 'auth/phone-already-in-use';
          throw error;
        }

        // link to email provider
        // linkWithCredential will fail if the credential are already linked to another user account
        const emailCredential = firebase.auth.EmailAuthProvider.credential(email, password);
        const { user: linkedUser } = await firebaseUser.linkWithCredential(emailCredential);

        // register app user
        const accessToken = await linkedUser.getIdToken();
        this.setAccessTokenClientSide(accessToken);
        await this.api.post<User>('/v1/auth/register', { email, ...rest });
        linkedUser.sendEmailVerification();
        return linkedUser;
      } catch (err) {
        firebaseUser.delete();
        console.error(err);
        throw err;
      } finally {
        this.confirmationResult = undefined;
      }
    } catch (err) {
      // User couldn't sign in (bad verification code?)
      console.error(err);
      throw err;
    }
  };

  /**
   * required logged-in user. this will fail if user's email is not verified.
   *
   * @param phone
   */
  requestUpdatePhone = async (phone: string): Promise<string> => {
    try {
      this.recaptchaVerifier ??= new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
      const verificationId = await this.phoneProvider.verifyPhoneNumber(phone, this.recaptchaVerifier);
      // SMS sent.
      return verificationId;
    } catch (err) {
      // Error. SMS not sent.
      throw err;
    }
  };

  confirmUpdatePhone = async (verificationId: string, code: string): Promise<void> => {
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    const firebaseUser = this.firebaseAuth.currentUser;
    // if (!!firebaseUser.providerData.find(provider => provider.providerId === 'phone')) {
    //   await firebaseUser.unlink('phone');
    // }
    return firebaseUser.updatePhoneNumber(phoneCredential);
  };

  sendSignInLinkToEmail = async (email: string): Promise<void> => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${process.env.NEXT_PUBLIC_DOMAIN}`,
      handleCodeInApp: true, // This must be true.
    };
    await this.firebaseAuth.sendSignInLinkToEmail(email, actionCodeSettings);
    window.localStorage.setItem(SIGN_IN_EMAIL, email);
  };

  isSignInLink = (link: string): boolean => {
    return this.firebaseAuth.isSignInWithEmailLink(link);
  };

  loginWithLink = async (
    email = window.localStorage.getItem(SIGN_IN_EMAIL),
    link = window.location.href,
  ): Promise<firebase.User> => {
    const result = await this.firebaseAuth.signInWithEmailLink(email, link);
    result.additionalUserInfo.isNewUser; // You can check if the user is new or existing
    window.localStorage.removeItem(SIGN_IN_EMAIL);
    const firebaseUser = result.user;
    const accessToken = await firebaseUser.getIdToken();
    this.setAccessTokenClientSide(accessToken);

    // create user in our system if not exist
    let user: User | undefined;
    try {
      user = await this.getMyProfile();
    } catch {}
    if (!user) {
      const { displayName: fullName, email, phoneNumber, photoURL } = firebaseUser;
      user = await this.api.post<User>('/v1/auth/register', {
        fullName,
        email,
        phone: phoneNumber,
        avatarUrl: photoURL,
      });
    }

    return firebaseUser;
  };

  loginWithEmail = async (email: string, password: string): Promise<firebase.User> => {
    await this.firebaseAuth.signInWithEmailAndPassword(email, password);
    const firebaseUser = this.firebaseAuth.currentUser;
    const accessToken = await firebaseUser.getIdToken();
    this.setAccessTokenClientSide(accessToken);

    // create user in our system if not exist
    let user: User | undefined;
    try {
      user = await this.getMyProfile();
    } catch {}
    if (!user) {
      const { displayName: fullName, email, phoneNumber, photoURL } = firebaseUser;
      user = await this.api.post<User>('/v1/auth/register', {
        fullName,
        email,
        phone: phoneNumber,
        avatarUrl: photoURL,
      });
    }

    return firebaseUser;
  };

  loginWithPassword = async (account: string, password: string): Promise<firebase.User> => {
    const { customToken } = await this.api.post<{ customToken: string }>('/v1/auth/login', { account, password });
    const { user: firebaseUser } = await this.firebaseAuth.signInWithCustomToken(customToken);
    const accessToken = await firebaseUser.getIdToken();
    this.setAccessTokenClientSide(accessToken);

    // create user in our system if not exist
    let user: User | undefined;
    try {
      user = await this.getMyProfile();
    } catch {}
    if (!user) {
      const { displayName: fullName, email, phoneNumber, photoURL } = firebaseUser;
      user = await this.api.post<User>('/v1/auth/register', {
        fullName,
        email,
        phone: phoneNumber,
        avatarUrl: photoURL,
      });
    }

    return firebaseUser;
  };

  loginSocial = async (provider: SocialProvider): Promise<firebase.User> => {
    switch (provider) {
      case SocialProvider.Facebook:
        await this.firebaseAuth.signInWithPopup(this.facebookProvider);
        break;
      case SocialProvider.Twitter:
        await this.firebaseAuth.signInWithPopup(this.twitterProvider);
        break;
      case SocialProvider.Google:
        await this.firebaseAuth.signInWithPopup(this.googleProvider);
        break;
      default:
        throw new Error('Unknown social');
    }
    const firebaseUser = this.firebaseAuth.currentUser;
    const accessToken = await firebaseUser.getIdToken();
    this.setAccessTokenClientSide(accessToken);

    // create user in our system if not exist
    let user: User | undefined;
    try {
      user = await this.getMyProfile();
    } catch {}
    if (!user) {
      const { displayName: fullName, email, phoneNumber, photoURL } = firebaseUser;
      user = await this.api.post<User>('/v1/auth/register', {
        fullName,
        email,
        phone: phoneNumber,
        avatarUrl: photoURL,
      });
    }

    return firebaseUser;
  };

  getFirebaseUser = (): firebase.User | null => {
    return this.firebaseAuth.currentUser;
  };

  sendEmailVerification = async (): Promise<void> => {
    const firebaseUser = this.firebaseAuth.currentUser;
    if (firebaseUser) {
      await firebaseUser.sendEmailVerification();
    }
  };

  sendPasswordResetEmail = async (email: string): Promise<void> => {
    await this.firebaseAuth.sendPasswordResetEmail(email);
  };

  verifyPasswordResetCode = async (code: string): Promise<void> => {
    await this.firebaseAuth.verifyPasswordResetCode(code);
  };

  confirmPasswordReset = async (code: string, password: string): Promise<void> => {
    await this.firebaseAuth.confirmPasswordReset(code, password);
  };

  updatePassword = async (currentPassword: string, newPassword: string): Promise<void> => {
    const firebaseUser = this.firebaseAuth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(firebaseUser.email, currentPassword);
    const isValid = await firebaseUser.reauthenticateWithCredential(cred);
    if (isValid) {
      await firebaseUser.updatePassword(newPassword);
    }
  };

  setPassword = async (newPassword: string): Promise<void> => {
    await this.api.patch('/v1/auth/password', { newPassword });
  };

  /**
   * Need to manually verify that the email belongs to the user (eg. via a passcode sent to email).
   * The call to linkWithCredential will fail if the credentials are already linked to another user account.
   * Example, logged in with social and email in different accounts.
   * In this situation, you must handle merging the accounts and associated data as appropriate for your app
   *
   * @param {string} email
   * @param {string} password
   */
  linkEmailAndPassword = async (email: string, password: string): Promise<firebase.User> => {
    const currentUser = this.firebaseAuth.currentUser;
    if (!currentUser) {
      throw new Error('Bad Request');
    }
    if (currentUser.providerData.find((provider) => provider.providerId === 'password')) {
      throw new Error('Bad Request');
    }
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    const { user } = await this.firebaseAuth.currentUser.linkWithCredential(credential);
    return user;
  };

  /**
   * Account linking will fail if the credentials are already linked to another user account.
   * In this situation, you must handle merging the accounts and associated data as appropriate for your app
   *
   * @param {SocialProvider} provider
   */
  linkSocialAccount = async (provider: SocialProvider): Promise<firebase.User> => {
    const currentUser = this.firebaseAuth.currentUser;
    if (!currentUser) {
      throw new Error('Bad Request');
    }
    let result: firebase.auth.UserCredential;
    switch (provider) {
      case SocialProvider.Facebook:
        result = await currentUser.linkWithPopup(this.facebookProvider);
        break;
      case SocialProvider.Twitter:
        result = await currentUser.linkWithPopup(this.twitterProvider);
        break;
      case SocialProvider.Google:
        result = await currentUser.linkWithPopup(this.googleProvider);
        break;
      default:
        throw new Error('Unknown social');
    }
    const { user, credential } = result;
    return user;
  };

  unlinkProvider = async (providerId: string): Promise<void> => {
    const currentUser = this.firebaseAuth.currentUser;
    if (!currentUser) {
      throw new Error('Bad Request');
    }
    await currentUser.unlink(providerId);
  };

  reauthenticate = async (): Promise<void> => {
    const currentUser = this.firebaseAuth.currentUser;
    if (!currentUser) {
      throw new Error('Bad Request');
    }
    // currentUser.reauthenticateWithPopup();
  };

  signOut = async (): Promise<void> => {
    await this.firebaseAuth.signOut();
  };

  getMyProfile = async (): Promise<User> => {
    return this.api.get<User>('/v1/auth/profile');
  };

  updateMyProfile = async (data: Partial<User>): Promise<void> => {
    await this.api.patch('/v1/auth/profile', data);
  };

  updateCreatorProfile = async (data: Partial<CreatorApplication>): Promise<void> => {
    await this.api.patch('/v1/auth/creator-profile', data);
  };

  getMyMetadata = async (): Promise<UserMetadata> => {
    return this.api.get<UserMetadata>('/v1/auth/metadata');
  };

  updateMyMetadata = async (data: DeepPartial<UserMetadata>): Promise<void> => {
    await this.api.patch('/v1/auth/metadata', data);
  };

  getDeviceId = (): string => {
    let deviceId = window.localStorage.getItem(DEVICE_ID_KEY);
    if (deviceId) {
      return deviceId;
    }
    deviceId = uuid();
    window.localStorage.setItem(DEVICE_ID_KEY, deviceId);
    return deviceId;
  };

  getCurrentSessionId = (): string => {
    return window.localStorage.getItem(SESSION_ID_KEY);
  };

  setCurrentSessionId = (uuid: string): void => {
    window.localStorage.setItem(SESSION_ID_KEY, uuid);
  };

  createSession = async (userSession: Partial<UserSession>): Promise<UserSession> => {
    return this.api.post<UserSession>('/v1/auth/sessions', userSession);
  };

  updateSession = async (
    uuid: string,
    userSession: Partial<UserSession> & { location?: IpLocation },
  ): Promise<void> => {
    return this.api.patch<void>(`/v1/auth/sessions/${uuid}`, userSession);
  };

  deleteSession = async (uuid: string): Promise<void> => {
    return this.api.delete(`/v1/auth/sessions/${uuid}`);
  };

  // https://stackoverflow.com/questions/43488605/detect-when-challenge-window-is-closed-for-google-recaptcha
  test = (): void => {
    if (window['recaptchaCloseListener']) {
      return;
    }

    window['recaptchaWindow'] = Array.from(document.getElementsByTagName('iframe')).find((iframe) =>
      iframe.src.startsWith('https://www.google.com/recaptcha/api2/bframe'),
    )?.parentNode?.parentNode;

    if (window['recaptchaWindow']) {
      window['recaptchaCloseListener'] = true;

      new MutationObserver(() => {
        if (window['recaptchaWindow']['style'].opacity == 0) {
          // recaptcha dialog is closed
          console.log('recaptcha dialog is closed');
        }
      }).observe(window['recaptchaWindow'], { attributes: true, attributeFilter: ['style'] });

      const overlay = window['recaptchaWindow'].firstElementChild;
      console.log('aaaaaaaaa', overlay);
      console.log('bbbbbbbbb', overlay?.attributes.style);
      if (overlay?.attributes.style) {
        // disable closing dialog when click on overlay
        overlay.attributes.style['pointer-events'] = 'none';
        console.log('cccccccccc', overlay?.attributes.style);
      }
    }
  };
}
