import { Instance, types } from 'mobx-state-tree';

import { Invoice, PaymentMethod, Subscription } from './StripeModel';

export enum CreatorApplicationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export const CreatorApplicationStatusColor = new Map<string, string>([
  [CreatorApplicationStatus.Approved, '#22CE8C'],
  [CreatorApplicationStatus.Rejected, '#ED4F2B'],
  [CreatorApplicationStatus.Pending, '#FECB47'],
]);

export const CreatorApplicationModel = types.model('CreatorApplication', {
  uuid: types.identifier,
  status: types.optional(types.enumeration(Object.values(CreatorApplicationStatus)), CreatorApplicationStatus.Pending),
  userUid: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),

  email: types.string,
  fullName: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  phoneCountryCode: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  bio: types.maybeNull(types.string),
  agency: types.maybeNull(types.string),
  photoUrl: types.maybeNull(types.string),
  videoUrl: types.maybeNull(types.string),
  videoThumbnailUrl: types.maybeNull(types.string),
  videoMetadata: types.optional(types.frozen(), {}),

  price: types.number,
  charities: types.maybeNull(types.string),
  donation: types.number,

  createdAt: types.maybeNull(types.string),
});

export type CreatorApplication = Instance<typeof CreatorApplicationModel>;

// export interface CreatorApplication {
//   uuid: string;
//   status: CreatorApplicationStatus;
//   userUid: string | null;
//   reason: string | null;
//
//   email: string;
//   fullName: string;
//   username: string;
//   phone: string | null;
//   phoneCountryCode: string | null;
//   category: string | null;
//   bio: string | null;
//   agency: string | null;
//   photoUrl: string;
//   videoUrl: string;
//
//   price: number;
//   charities: string | null;
//   donation: number;
// }

export interface InstagramLoginResponse {
  username: string;
  needOTP: boolean;
  twoFactorIdentifier?: string;
  verificationMethod?: '0' | '1';
}

export interface InstagramVerifyOtpRequest {
  username: string;
  code: string;
  twoFactorIdentifier: string;
  verificationMethod: '0' | '1';
}

export interface InstagramStatusResponse {
  needLogin: boolean;
}

export interface BestieResponse {
  pk: number;
  username: string;
  fullName: string;
  isVerified: boolean;
  isBestie: boolean;
}

export interface PaymentHistoryResponse {
  subscriptions: Subscription[];
  invoices: Invoice[];
  paymentMethods: PaymentMethod[];
  defaultPaymentMethodId: string | null;
}
