import { IpInfo } from '#models/model-types/UserModel';

import { GeneralApi } from './base/GeneralApi';

export default class IpService {
  constructor(private generalApi: GeneralApi) {}

  getIpLocation = async (ip = ''): Promise<IpInfo> => {
    const ipinfo = await this.generalApi.get<IpInfo>(
      `https://ipinfo.io/${ip}?token=${process.env.NEXT_PUBLIC_IPINFO_TOKEN}`,
    );
    ipinfo.time = Date.now();
    return ipinfo;
  };
}
