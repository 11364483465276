import { cast, flow, types } from 'mobx-state-tree';

import { PAGE_SIZE_DEFAULT } from '#constants/app.constant';
import { CreatorApplication, CreatorApplicationModel } from '#models/model-types/CreatorModel';
import services from '#services';
import { ListResponse, RequestParams } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const CreatorApplicationListModel = types
  .model({
    data: types.optional(types.array(CreatorApplicationModel), []),
    total: types.optional(types.number, 0),
    pageCount: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, PAGE_SIZE_DEFAULT),
    sort: types.optional(types.array(types.string), []),
    filter: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .volatile(() => ({
    isLoading: types.optional(types.boolean, false).create(),
    isUpdating: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    getCreatorApplications: flow(function* (requestParams?: RequestParams) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<CreatorApplication> =
          yield services.admin.getCreatorApplications({
            limit,
            page,
            sort,
            filter,
          });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions((self) => ({
    getCreatorApplication: flow(function* (uuid: string) {
      const application: CreatorApplication = yield services.admin.getCreatorApplication(uuid);
      return application;
    }),
  }))
  .actions((self) => ({
    updateCreatorApplication: flow(function* (uuid: string, body: Partial<CreatorApplication>) {
      self.isUpdating = true;
      try {
        yield services.admin.updateCreatorApplication(uuid, body);
      } catch (err) {
        throw err;
      } finally {
        self.isUpdating = false;
      }
    }),
  }))
  .actions((self) => ({
    resendCreatorApplicationStatusEmail: flow(function* (uuid: string) {
      self.isUpdating = true;
      try {
        yield services.admin.resendCreatorApplicationStatusEmail(uuid);
      } catch (err) {
        throw err;
      } finally {
        self.isUpdating = false;
      }
    }),
  }));
