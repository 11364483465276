import { getTimezoneOffset } from 'date-fns-tz';
import firebase from 'firebase';
import merge from 'lodash/merge';
import { cast, flow, getRoot, Instance, types } from 'mobx-state-tree';

import { SocialProvider } from '#constants/app.constant';
import {
  CreatorApplication,
  InstagramLoginResponse,
  InstagramStatusResponse,
  InstagramVerifyOtpRequest,
} from '#models/model-types/CreatorModel';
import {
  DEFAULT_USER_METADATA,
  IpInfo,
  IpLocation,
  RegisterRequest,
  User,
  UserMetadata,
  UserMetadataModel,
  UserModel,
  UserSession,
  UserSessionType,
  UserSettings,
} from '#models/model-types/UserModel';
import services from '#services';
import { DeepPartial } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const AuthStoreModel = types
  .model('AuthStore')
  .props({
    user: types.maybeNull(UserModel),
    userMetadata: types.optional(UserMetadataModel, DEFAULT_USER_METADATA),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .volatile((self) => ({
    isUserLoaded: types.optional(types.boolean, false).create(),
    sendingEmail: types.optional(types.boolean, false).create(),
    resetingPassword: types.optional(types.boolean, false).create(),
    updatingPassword: types.optional(types.boolean, false).create(),
    updatingProfile: types.optional(types.boolean, false).create(),
    updatingProfilePhoto: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    setUserLoaded: function (loaded: boolean) {
      self.isUserLoaded = loaded;
    },
    setAccessTokenClientSide: function (accessToken: string) {
      services.auth.setAccessTokenClientSide(accessToken);
    },
    clearAccessTokenClientSide: function () {
      services.auth.clearAccessTokenClientSide();
      self.user = null;
    },
  }))
  .actions((self) => ({
    onIdTokenChanged: function (callback: (a: firebase.User | null) => any) {
      return services.auth.onIdTokenChanged(callback);
    },
  }))
  .actions((self) => ({
    getFirebaseUser: function () {
      return services.auth.getFirebaseUser();
    },
  }))
  .actions((self) => ({
    getAccessToken: flow(function* () {
      try {
        const accessToken: string = yield services.auth.newAccessTokenClientSide();
        return accessToken;
      } catch (error) {
        return null;
      }
    }),
  }))
  .actions((self) => ({
    getMyProfile: flow(function* () {
      try {
        const user: User = yield services.auth.getMyProfile();
        self.user = cast(user);
        return user;
      } catch (error) {
        console.error('getMyProfile error', error.message);
        return null;
      }
    }),
  }))
  .actions((self) => ({
    getMyMetadata: flow(function* () {
      try {
        const userMetadata: UserMetadata = yield services.auth.getMyMetadata();
        self.userMetadata = cast(userMetadata);
        return userMetadata;
      } catch (error) {
        return null;
      }
    }),
  }))
  .actions((self) => ({
    updateProfile: flow(function* (data: Partial<User>) {
      self.updatingProfile = true;
      try {
        yield services.auth.updateMyProfile(data);
        self.user = cast({ ...self.user, ...data });
      } catch (err) {
        throw err;
      } finally {
        self.updatingProfile = false;
      }
    }),
  }))
  .actions((self) => ({
    updateCreatorProfile: flow(function* (data: Partial<CreatorApplication>) {
      self.updatingProfile = true;
      try {
        yield services.auth.updateCreatorProfile(data);
        yield self.getMyProfile();
      } catch (err) {
        throw err;
      } finally {
        self.updatingProfile = false;
      }
    }),
  }))
  .actions((self) => ({
    updateSettings: flow(function* (settings: DeepPartial<UserSettings>) {
      self.userMetadata = {
        ...self.userMetadata,
        settings: merge(self.userMetadata?.settings, settings),
      };
      yield services.auth.updateMyMetadata({ settings });
    }),
  }))
  .actions((self) => ({
    checkEmailExists: flow(function* (email: string) {
      const existing: boolean = yield services.auth.checkEmailExists(email);
      return existing;
    }),
    validateUsername: flow(function* (username: string) {
      const valid: boolean = yield services.auth.validateUsername(username);
      return valid;
    }),
    checkPhoneExists: flow(function* (phone: string) {
      const existing: boolean = yield services.auth.checkPhoneExists(phone);
      return existing;
    }),
    checkPasswordAuthExists: flow(function* (email: string) {
      const res: { existing: boolean; passwordResetRequired: boolean } = yield services.auth.checkPasswordAuthExists(
        email,
      );
      return res;
    }),
  }))
  .actions((self) => ({
    signUpWithEmail: flow(function* (data: RegisterRequest) {
      const user: firebase.User = yield services.auth.signUpWithEmail(data);
      yield self.getMyProfile();
      return user;
    }),
  }))
  .actions((self) => ({
    requestSignUpWithPhone: flow(function* (phone: string) {
      yield services.auth.requestSignUpWithPhone(phone);
    }),
  }))
  .actions((self) => ({
    confirmSignUpWithPhone: flow(function* (code: string, data: RegisterRequest) {
      const user: firebase.User = yield services.auth.confirmSignUpWithPhone(code, data);
      yield self.getMyProfile();
      return user;
    }),
  }))
  .actions((self) => ({
    sendSignInLinkToEmail: flow(function* (email: string) {
      self.sendingEmail = true;
      try {
        yield services.auth.sendSignInLinkToEmail(email);
      } catch (err) {
        throw err;
      } finally {
        self.sendingEmail = false;
      }
    }),
  }))
  .actions((self) => ({
    loginWithPassword: flow(function* (account: string, password: string) {
      const user: firebase.User = yield services.auth.loginWithPassword(account, password);
      yield self.getMyProfile();
      return user;
    }),
    loginWithEmail: flow(function* (email: string, password: string) {
      const user: firebase.User = yield services.auth.loginWithEmail(email, password);
      yield self.getMyProfile();
      return user;
    }),
    loginWithLink: flow(function* (email?: string, link?: string) {
      const user: firebase.User = yield services.auth.loginWithLink(email, link);
      yield self.getMyProfile();
      return user;
    }),
    loginSocial: flow(function* (provider: SocialProvider) {
      const user: firebase.User = yield services.auth.loginSocial(provider);
      yield self.getMyProfile();
      return user;
    }),
  }))
  .actions((self) => ({
    logout: flow(function* () {
      try {
        const currentSessionId = services.auth.getCurrentSessionId();
        if (currentSessionId) {
          yield services.auth.deleteSession(currentSessionId);
        }
        yield services.auth.signOut();
      } catch (err) {
        console.error('logout error', err.message);
      }
      services.auth.clearAccessTokenClientSide();
      self.user = null;
    }),
  }))
  .actions((self) => ({
    sendEmailVerification: flow(function* () {
      self.sendingEmail = true;
      try {
        yield services.auth.sendEmailVerification();
      } catch (err) {
        throw err;
      } finally {
        self.sendingEmail = false;
      }
    }),
  }))
  .actions((self) => ({
    sendPasswordResetEmail: flow(function* (email: string) {
      self.sendingEmail = true;
      try {
        yield services.auth.sendPasswordResetEmail(email);
      } catch (err) {
        throw err;
      } finally {
        self.sendingEmail = false;
      }
    }),
  }))
  .actions((self) => ({
    verifyPasswordResetCode: flow(function* (code: string) {
      yield services.auth.verifyPasswordResetCode(code);
    }),
  }))
  .actions((self) => ({
    confirmPasswordReset: flow(function* (code: string, password: string) {
      yield services.auth.confirmPasswordReset(code, password);
    }),
  }))
  .actions((self) => ({
    updatePassword: flow(function* (currentPassword: string, newPassword: string) {
      self.updatingPassword = true;
      try {
        yield services.auth.updatePassword(currentPassword, newPassword);
      } catch (err) {
        throw err;
      } finally {
        self.updatingPassword = false;
      }
    }),
    setPassword: flow(function* (newPassword: string) {
      self.updatingPassword = true;
      try {
        yield services.auth.setPassword(newPassword);
      } catch (err) {
        throw err;
      } finally {
        self.updatingPassword = false;
      }
    }),
  }))
  .actions((self) => ({
    requestUpdatePhone: flow(function* (phone: string) {
      const verificationId: string = yield services.auth.requestUpdatePhone(phone);
      return verificationId;
    }),
    confirmUpdatePhone: flow(function* (verificationId: string, code: string) {
      yield services.auth.confirmUpdatePhone(verificationId, code);
    }),
  }))
  .actions((self) => ({
    instagramLogin: flow(function* (username: string, password: string) {
      const response: InstagramLoginResponse = yield services.instagram.login(username, password);
      return response;
    }),
    instagramVerify: flow(function* (request: InstagramVerifyOtpRequest) {
      yield services.instagram.verify(request);
    }),
    instagramStatus: flow(function* (username: string) {
      const status: InstagramStatusResponse = yield services.instagram.status(username);
      return status;
    }),
  }))
  .actions((self) => ({
    createSession: flow(function* () {
      try {
        const currentSessionId = services.auth.getCurrentSessionId();
        if (!currentSessionId) {
          const deviceId = services.auth.getDeviceId();
          const createdSession: UserSession = yield services.auth.createSession({
            type: UserSessionType.Web,
            deviceId,
          });
          services.auth.setCurrentSessionId(createdSession.uuid);
          return createdSession;
        }
      } catch (err) {
        console.error('createSession error', err.message);
      }
      return null;
    }),
    updateSession: flow(function* (userSession: Partial<UserSession>) {
      const currentSessionId = services.auth.getCurrentSessionId();
      try {
        if (currentSessionId) {
          let location: IpLocation | undefined;
          try {
            let ipInfo: IpInfo | undefined;
            const cachedIpInfo = window.localStorage.getItem('ipinfo');
            if (cachedIpInfo) {
              ipInfo = JSON.parse(cachedIpInfo);
              services.misc.addLog('updated_session_ipinfo', {
                context: 'in localstorage',
                isExpired: ipInfo.time < Date.now() - 7 * 24 * 60 * 60 * 1000,
                userUuid: userSession.uuid,
                currentSessionId,
                ipInfo,
              });

              if (ipInfo.time < Date.now() - 7 * 24 * 60 * 60 * 1000) {
                ipInfo = undefined;
              }
            }
            if (!ipInfo) {
              ipInfo = yield services.ip.getIpLocation();
              services.misc.addLog('updated_session_ipinfo', {
                context: 'new request',
                currentSessionId,
                userUuid: userSession.uuid,
                ipInfo,
              });
              window.localStorage.setItem('ipinfo', JSON.stringify(ipInfo));
            }
            location = {
              countryCode: ipInfo.country,
              region: ipInfo.region,
              city: ipInfo.city,
              offset: getTimezoneOffset(ipInfo.timezone) / 1000,
            };
            services.misc.addLog('updated_session_ipinfo', {
              context: 'final Ipinfo',
              currentSessionId,
              userUuid: userSession.uuid,
              ipInfo,
            });
          } catch (err) {
            console.error(err);
            services.misc.addLog('updated_session_ipinfo_error', { userUuid: userSession.uuid, currentSessionId, err });
          }
          yield services.auth.updateSession(currentSessionId, { ...userSession, location });
        } else {
          services.misc.addLog('updated_session_ipinfo_error', {
            context: 'not exist currentSessionId',
            currentSessionId,
            userUuid: userSession.uuid,
          });
        }
      } catch (err) {
        console.error('updateSession error', err.message);
        services.misc.addLog('updated_session_ipinfo_error', {
          context: 'end function updateSession',
          currentSessionId,
          userUuid: userSession.uuid,
          err,
        });
      }
    }),
  }))
  .actions((self) => ({
    linkEmailAndPassword: flow(function* (email: string, password: string) {
      self.updatingPassword = true;
      try {
        yield services.auth.linkEmailAndPassword(email, password);
      } catch (err) {
        throw err;
      } finally {
        self.updatingPassword = false;
      }
    }),
  }));

type AuthStore = Instance<typeof AuthStoreModel>;
