import { IAnyModelType, Instance, types } from 'mobx-state-tree';

import { CreatorApplicationModel } from '#models/model-types/CreatorModel';
import { SubscriptionModel } from '#models/model-types/StripeModel';

export enum UserStatus {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Banned = 'BANNED',
}

export const UserStatusColor = new Map<string, string>([
  [UserStatus.Inactive, '#FECB47'],
  [UserStatus.Banned, '#ED4F2B'],
  [UserStatus.Active, '#22CE8C'],
]);

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER',
}

export const UserModel = types.model('User', {
  uid: types.identifier,
  isCreator: types.optional(types.boolean, false),
  visible: types.optional(types.boolean, true),
  fullName: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  avatarUrl: types.maybeNull(types.string),
  creatorFullName: types.maybeNull(types.string),
  creatorUsername: types.maybeNull(types.string),
  creatorPhotoUrl: types.maybeNull(types.string),
  creatorVideoUrl: types.maybeNull(types.string),
  creatorVideoThumbnailUrl: types.maybeNull(types.string),
  creatorVideoMetadata: types.optional(types.frozen(), {}),
  creatorSlug: types.maybeNull(types.string),
  price: types.optional(types.number, 0),
  bio: types.maybeNull(types.string),
  categoryNames: types.optional(types.array(types.string), []),
  application: types.maybeNull(CreatorApplicationModel),

  status: types.optional(types.enumeration(Object.values(UserStatus)), UserStatus.Active),
  role: types.optional(types.enumeration(Object.values(UserRole)), UserRole.User),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  phoneCountryCode: types.maybeNull(types.string),

  createdAt: types.optional(types.string, () => new Date().toISOString()),
  updatedAt: types.optional(types.string, () => new Date().toISOString()),

  mySubscriptions: types.optional(types.array(types.late((): IAnyModelType => SubscriptionModel)), []),
  subscriberSubscriptions: types.optional(types.array(types.late((): IAnyModelType => SubscriptionModel)), []),
  totalSpendings: types.optional(types.number, 0),
  totalRevenue: types.optional(types.number, 0),
  totalSubscribers: types.optional(types.number, 0),

  numberOfSubscribers: types.optional(types.number, 0),
  waitlistEnabled: types.optional(types.boolean, false),
});

export type User = Omit<Instance<typeof UserModel>, 'categoryNames'> & {
  categoryNames?: string[];
};

export interface UserSettings {
  Notifications?: {
    NewPayment?: boolean;
  };
}

export const UserNotificationSettingsModel = types.model({
  NewPayment: types.maybe(types.boolean),
});

export const UserSettingsModel = types.model({
  Notifications: types.maybe(UserNotificationSettingsModel),
});

export const UserMetadataModel = types.model({
  country: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  tzOffset: types.maybeNull(types.number),
  settings: types.maybeNull(UserSettingsModel),
});

export type UserMetadata = Instance<typeof UserMetadataModel>;

export const DEFAULT_USER_METADATA: UserMetadata = {
  country: null,
  state: null,
  city: null,
  tzOffset: null,
  settings: {
    Notifications: {
      NewPayment: true,
    },
  },
};

export enum UserSessionType {
  Web = 'web',
}

export const UserSessionExtraDataModel = types.model({});

export const UserSessionModel = types.model({
  uuid: types.string,
  type: types.enumeration(Object.values(UserSessionType)),
  deviceId: types.string,
  lastActive: types.maybeNull(types.string),
  fcmToken: types.maybeNull(types.string),
  extraData: types.maybeNull(UserSessionExtraDataModel),
});

export type UserSession = Instance<typeof UserSessionModel>;

export interface RegisterRequest {
  applicationId?: string;
  email?: string;
  phone?: string;
  phoneCountryCode?: string;
  password?: string;
  fullName?: string;
  username?: string;
  avatarUrl?: string;
}

export interface IpInfo {
  time: number;
  ip: string;
  city: string;
  region: string;
  country: string;
  timezone: string; // "America/New_York"
}

export interface IpLocation {
  countryCode: string;
  region: string;
  city: string;
  offset: number;
}
