import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { GreenringApiError } from '../../errors';

export class GreenringApi {
  private readonly axios: AxiosInstance;
  private accessToken?: string;
  private newAccessTokenFunc?: (forceRefresh: boolean) => Promise<string>;

  constructor(private axiosConfig: AxiosRequestConfig, private retries = 0) {
    this.axios = axios.create(axiosConfig);
    const requestInterceptor = this.axios.interceptors.request.use(this.onRequest, this.onRequestError);
    const responseInterceptor = this.axios.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  public setAccessToken = (accessToken?: string): void => {
    this.accessToken = accessToken;
  };

  public setNewAccessTokenFunc = (func?: () => Promise<string>): void => {
    this.newAccessTokenFunc = func;
  };

  public get = async <T>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.get<T>(url, { ...config, params });
    return data;
  };

  public post = async <T>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.post<T>(url, body, config);
    return data;
  };

  public patch = async <T>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.patch<T>(url, body, config);
    return data;
  };

  public put = async <T>(url: string, body?: unknown, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.put<T>(url, body, config);
    return data;
  };

  public delete = async <T>(url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.delete<T>(url, { ...config, params });
    return data;
  };

  public request = async <T>(config: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.axios.request<T>(config);
    return data;
  };

  private onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    // Do something before request is sent
    config['retryCount'] = config['retryCount'] || 0;
    if (!config.headers['Authorization'] && this.accessToken) {
      config.headers['Authorization'] = `Bearer ${this.accessToken}`;
    }
    return config;
  };

  private onRequestError = (error: AxiosError) => {
    // Do something with request error

    const { config, request } = error;
    if (request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js
      console.error('api request error', error.code, error.message, request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('api request error', error.code, error.message);
    }

    // retry API request
    if (config['retryCount'] < this.retries) {
      config['retryCount'] += 1;
      return this.axios.request(config);
    }

    throw error;
  };

  private onResponse = async (response: AxiosResponse): Promise<AxiosResponse> => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  };

  private onResponseError = async (error: AxiosError): Promise<void> => {
    // Do something with response error

    const { config, response } = error;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (
        response.status === 401 &&
        config['retryCount'] < this.retries &&
        !!this.newAccessTokenFunc &&
        !config.url?.includes('/v1/auth/access-token') &&
        !config.url?.includes('/v1/auth/logout')
      ) {
        try {
          const accessToken = await this.newAccessTokenFunc(true);
          this.accessToken = accessToken;
          config['retryCount'] += 1;
          config.headers['Authorization'] = `Bearer ${accessToken}`;
          return this.axios.request(config);
        } catch (newAccessTokenError) {
          console.warn('api newAccessToken error', newAccessTokenError.message);
          throw new GreenringApiError(response.status, 'RefreshTokenError', 'Getting a fresh session', response);
        }
      }
      throw new GreenringApiError(
        response.status,
        response.data.error || 'Unknown',
        response.data.message || `Unknown error. ${config.method} ${config.url}`,
        response,
      );
    }

    throw error;
  };
}
