import { cast, flow, types } from 'mobx-state-tree';

import { PAGE_SIZE_DEFAULT } from '#constants/app.constant';
import { CreatorApplication } from '#models/model-types/CreatorModel';
import { User, UserModel } from '#models/model-types/UserModel';
import services from '#services';
import { ListResponse, RequestParams } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const UserListModel = types
  .model({
    data: types.optional(types.array(UserModel), []),
    total: types.optional(types.number, 0),
    pageCount: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, PAGE_SIZE_DEFAULT),
    sort: types.optional(types.array(types.string), []),
    filter: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .volatile(() => ({
    isLoading: types.optional(types.boolean, false).create(),
    isUpdating: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    getUsers: flow(function* (requestParams?: RequestParams, isCreator = false) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<User> = yield (
          isCreator ? services.admin.getCreators : services.admin.getConsumers
        )({
          limit,
          page,
          sort,
          filter,
        });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions((self) => ({
    getConsumers: flow(function* (requestParams?: RequestParams) {
      return self.getUsers(requestParams, false);
    }),
    getCreators: flow(function* (requestParams?: RequestParams) {
      return self.getUsers(requestParams, true);
    }),
  }))
  .actions((self) => ({
    getCreator: flow(function* (uid: string) {
      const creator: User = yield services.admin.getCreator(uid);
      return creator;
    }),
  }))
  .actions((self) => ({
    updateCreator: flow(function* (uid: string, body: Partial<User>) {
      self.isUpdating = true;
      try {
        yield services.admin.updateCreator(uid, body);
        for (const creator of self.data) {
          if (creator.uid === uid) {
            Object.assign(creator, body);
            break;
          }
        }
      } catch (err) {
        throw err;
      } finally {
        self.isUpdating = false;
      }
    }),
    updateCreatorProfile: flow(function* (uid: string, data: Partial<CreatorApplication>) {
      self.isUpdating = true;
      try {
        yield services.admin.updateCreatorProfile(uid, data);
      } catch (err) {
        throw err;
      } finally {
        self.isUpdating = false;
      }
    }),
  }));
