import { cast, flow, types } from 'mobx-state-tree';

import { PAGE_SIZE_DEFAULT } from '#constants/app.constant';
import {
  BulkRefundResponse,
  Subscription,
  SubscriptionModel,
  UpdateSubscriptionRequest,
} from '#models/model-types/StripeModel';
import services from '#services';
import { ListResponse, RequestParams } from '#types';

import { withEnvironment } from '../extensions/with-environment';

export const SubscriptionListModel = types
  .model({
    data: types.optional(types.array(SubscriptionModel), []),
    total: types.optional(types.number, 0),
    pageCount: types.optional(types.number, 0),
    page: types.optional(types.number, 1),
    limit: types.optional(types.number, PAGE_SIZE_DEFAULT),
    sort: types.optional(types.array(types.string), []),
    filter: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .volatile(() => ({
    isLoading: types.optional(types.boolean, false).create(),
    isSubmitting: types.optional(types.boolean, false).create(),
    isRefunding: types.optional(types.boolean, false).create(),
  }))
  .actions((self) => ({
    getSubscriptions: flow(function* (requestParams?: RequestParams) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<Subscription> = yield services.admin.getSubscriptions({
          limit,
          page,
          sort,
          filter,
        });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    getCreatorSubscribers: flow(function* (uid: string, requestParams?: RequestParams) {
      self.isLoading = true;
      try {
        const page = requestParams?.page ?? (self.page === 1 ? 1 : self.page + 1);
        const limit = requestParams?.limit ?? self.limit;
        const sort = requestParams?.sort ?? self.sort;
        const filter = requestParams?.filter ?? self.filter;
        self.data = cast([]);
        self.page = page;
        self.limit = limit;
        self.sort = cast(sort);
        self.filter = cast(filter);
        const { data, total, pageCount }: ListResponse<Subscription> = yield services.admin.getCreatorSubscribers(uid, {
          limit,
          page,
          sort,
          filter,
        });
        self.data = cast(data);
        self.total = total;
        self.pageCount = pageCount;
      } catch (error) {
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    cancelSubscription: flow(function* (uuid: string) {
      self.isSubmitting = true;
      try {
        yield services.admin.cancelSubscription(uuid);
      } catch (error) {
        throw error;
      } finally {
        self.isSubmitting = false;
      }
    }),
    updateSubscription: flow(function* (uuid: string, request: UpdateSubscriptionRequest) {
      self.isSubmitting = true;
      try {
        yield services.admin.updateSubscription(uuid, request);
      } catch (error) {
        throw error;
      } finally {
        self.isSubmitting = false;
      }
    }),
    refundSubscriptions: flow(function* (uuids: string[]) {
      self.isRefunding = true;
      try {
        const response: BulkRefundResponse = yield services.admin.refundSubscriptions(uuids);
        return response;
      } catch (error) {
        throw error;
      } finally {
        self.isRefunding = false;
      }
    }),
  }));
