import { AxiosResponse } from 'axios';

import {
  CompleteMultipartUploadingRequest,
  GetSignedPartsRequest,
  GetSignedPartsResponse,
  GetSignedUrlRequest,
  GetSignedUrlResponse,
  InitMultipartUploadResponse,
} from '#models/model-types/FileModel';

import { GeneralApi } from './base/GeneralApi';
import { GreenringApi } from './base/Greenring.api';

export default class FileService {
  constructor(private greenRingApi: GreenringApi, private generalApi: GeneralApi) {}

  getSignedUrl = async (params: GetSignedUrlRequest): Promise<GetSignedUrlResponse> => {
    return this.greenRingApi.get<GetSignedUrlResponse>('/v1/files/signed-url', params);
  };

  initMultipartUpload = async (params: GetSignedUrlRequest): Promise<InitMultipartUploadResponse> => {
    return this.greenRingApi.get<InitMultipartUploadResponse>('/v1/files/multipart', params);
  };

  getSignedParts = async (params: GetSignedPartsRequest): Promise<GetSignedPartsResponse> => {
    return this.greenRingApi.get<GetSignedPartsResponse>('/v1/files/multipart/signed-parts', params);
  };

  abortMultipartUpload = async (path: string, uploadId: string): Promise<void> => {
    await this.greenRingApi.delete('/v1/files/multipart', { path, uploadId });
  };

  completeMultipartUpload = async (request: CompleteMultipartUploadingRequest): Promise<void> => {
    await this.greenRingApi.post('/v1/files/multipart', request);
  };

  uploadToS3 = async (
    signedUrl: string,
    file: Blob,
    onProgress?: ({ percent: number }) => void,
  ): Promise<AxiosResponse> => {
    return await this.generalApi.requestWithResponse({
      method: 'PUT',
      url: signedUrl,
      data: file,
      onUploadProgress: (e) => {
        onProgress?.({ percent: (e.loaded / e.total) * 100 });
      },
      headers: {
        'Content-Type': file.type,
        'Cache-Control': `max-age=${30 * 24 * 3600}`, // in seconds
        // 'Content-Disposition': 'attachment',
      },
    });
  };
}
